import Vue from 'vue'
import VueRouter from 'vue-router'
import { createRouterLayout } from 'vue-router-layout'
import store from '@/store'

Vue.use(VueRouter)

const RouterLayout = createRouterLayout(layout => {
  return import('@/layouts/' + layout + '.vue')
})

const routes = [{
  path: '/',
  redirect: {
    name: 'login'
  },
  component: RouterLayout,
  children: [
    {
      name: 'provisional_register',
      path: '/provisional_register',
      component: () => import('@/pages/provisional_register/index'),
      meta: { title: '仮登録', guest: true }
    },
    {
      name: 'provisional_register_confirm',
      path: '/provisional_register/confirm',
      component: () => import('@/pages/provisional_register/confirm'),
      meta: { title: '仮登録確認', guest: true }
    },
    {
      name: 'provisional_register_completion',
      path: '/provisional_register/completion',
      component: () => import('@/pages/provisional_register/completion'),
      meta: { title: '仮登録完了', guest: true }
    },
    {
      name: 'register',
      path: '/register',
      component: () => import('@/pages/register/index'),
      meta: { title: '本登録', guest: true }
    },
    {
      name: 'register_confirm',
      path: '/register/confirm',
      component: () => import('@/pages/register/confirm'),
      meta: { title: '本登録確認', guest: true }
    },
    {
      name: 'register_completion',
      path: '/register/completion',
      component: () => import('@/pages/register/completion'),
      meta: { title: '本登録完了', guest: true }
    },
    {
      name: 'organizations',
      path: '/organizations',
      component: () => import('@/pages/organizations/all'),
      meta: { title: '医療機関一覧画面', requiresAuth: true }
    },
    {
      name: 'patients',
      path: '/organizations/:organization_id(\\d+)?/patients',
      component: () => import('@/pages/organizations/_organization_id/patients/all'),
      meta: { title: 'すべての患者', requiresAuth: true }
    },
    {
      name: 'patients-request',
      path: '/organizations/:organization_id(\\d+)?/patients/request',
      component: () => import('@/pages/organizations/_organization_id/patients/request'),
      meta: { title: '連携申請中の患者', requiresAuth: true }
    },
    {
      name: 'patients-program',
      path: '/organizations/:organization_id(\\d+)?/patients/:program([a-zA-Z]+)?',
      component: () => import('@/pages/organizations/_organization_id/patients/_program'),
      meta: { title: 'パーキンソン病疾患管理プログラム適応中の患者', requiresAuth: true }
    },
    {
      name: 'patients-id',
      path: '/organizations/:organization_id(\\d+)?/patients/:id(\\d+)?',
      component: () => import('@/pages/organizations/_organization_id/patients/_id'),
      meta: { title: '患者ダッシュボード', requiresAuth: true }
    },
    {
      name: 'patients-qrcode',
      path: '/organizations/:organization_id(\\d+)?/patients/qrcode/:id(\\d+)?',
      component: () => import('@/pages/organizations/_organization_id/patients/_qrcode'),
      meta: { title: 'QRコード連携', requiresAuth: true }
    },
    {
      name: 'admin',
      path: '/organizations/:organization_id(\\d+)?/admin',
      component: () => import('@/pages/organizations/_organization_id/admin/index'),
      meta: { title: '管理メニュー', requiresAuth: true }
    },
    {
      name: 'doctors-id',
      path: '/organizations/:organization_id(\\d+)?/doctors/:id(\\d+)?',
      component: () => import('@/pages/organizations/_organization_id/doctors/_id'),
      meta: { title: '医師プロフィール', requiresAuth: true }
    },
    {
      name: 'login',
      path: '/login',
      component: () => import('@/pages/login/index'),
      meta: { title: 'ログイン', guest: true }
    },
    {
      name: 'setting_password',
      path: '/setting_password',
      component: () => import('@/pages/setting_password/index'),
      meta: { title: 'パスワード設定', guest: true }
    }, 
    {
      name: 'setting_password_completion',
      path: '/setting_password/completion',
      component: () => import('@/pages/setting_password/completion'),
      meta: { title: 'パスワード設定', guest: true }
    },
    {
      name: 'reset_password',
      path: '/reset_password',
      component: () => import('@/pages/reset_password'),
      meta: { title: 'パスワード再設定', guest: true }
    },
    {
      name: 'support',
      path: '/support',
      component: () => import('@/pages/support/index'),
      meta: { title: 'サポート', requiresAuth: true }
    },
    {
      name: 'unauthorized',
      path: '/unauthorized',
      component: () => import('@/pages/unauthorized'),
      meta: { title: '401 Unauthorized', guest: false }
    },
    {
      name: 'not_found',
      path: '/not_found',
      component: () => import('@/pages/not_found'),
      meta: { title: '404 Not Found', guest: false }
    },
    {
      name: 'bad_request',
      path: '/bad_request',
      component: () => import('@/pages/bad_request'),
      meta: { title: '400 Bad Request', guest: false }
    },
    {
      name: 'internal_server_error',
      path: '/internal_server_error',
      component: () => import('@/pages/internal_server_error'),
      meta: { title: '500 Internal Server Error', guest: false }
    }
  ]
}]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['auth/isAuthenticated']) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters['auth/isAuthenticated']) {
      let current_organizatioin_id = localStorage.getItem('current_organization_id')
      if (localStorage.getItem('current_user_authority') == 99) {
        next('/organizations')
      } else {
        next(`/organizations/${current_organizatioin_id}/patients`)
      }
      return
    }
    next()
  } else {
    next()
  }
})

export default router
