import axios from 'axios'

axios.defaults.withCredentials = true

// Add a request interceptor
axios.interceptors.request.use(function (config) {
  let token = ''
  if (localStorage.getItem('access_token') != null && localStorage.getItem('access_token') != '') {
    token = `Bearer ${localStorage.getItem('access_token')}`
  }
  config.headers.common['Authorization'] = token
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  if (response.headers['access_token_expired_at'] != null) {
    localStorage.setItem('access_token_expired_at', response.headers['access_token_expired_at'])
  }
  return response
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  return Promise.reject(error)
})
