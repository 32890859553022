import Vuex from 'vuex'
import Vue from 'vue'
import OrganizationApi from '@/plugins/axios/api/organization'

//load Vuex
Vue.use(Vuex)

const initialState = () => ({
  status: '',
  errors: '',
  organization: {},
  organizations: []
})

//to handle state
const state = initialState()

//to handle state
const getters = {
  organization: state => state.organization,
  organizations: state => state.organizations
}

//to handle mutations
const mutations = {
  SET_REQUEST(state) {
    state.status = 'loading'
  },
  SET_SUCCESS(state) {
    state.status = 'success'
  },
  SET_BAD_REQUEST_ERROR(state, errors) {
    state.status = 'bad_request'
    state.errors = errors
  },
  SET_UNAUTHORIZED_ERROR(state, errors) {
    state.status = 'unauthorized_error'
    state.errors = errors
  },
  SET_NOT_FOUND_ERROR(state, errors) {
    state.status = 'not_found_error'
    state.errors = errors
  },
  SET_ERROR(state, errors) {
    state.status = 'error'
    state.errors = errors
  },
  SET_ORGANIZATION(state, organization) {
    state.organization = organization
  },
  SET_ORGANIZATIONS(state, organizations) {
    state.organizations = organizations
  },
  SET_RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}

//to handle actions
const actions = {
  getOrganization({commit}, organizationId) {
    return new Promise((resolve, reject) => {
      commit('SET_REQUEST')
      OrganizationApi.getOrganization(organizationId)
        .then(response => {
          let o = response.data
          commit('SET_ORGANIZATION', o)
          resolve(o)
        })
        .catch(error => {
          let errors = ''
          if (error.response.data && error.response.data.errors) {
            errors = error.response.data.errors
          }
          if (error.response.status === 400) {
            commit('SET_BAD_REQUEST_ERROR', errors)
          } else if (error.response.status === 401) {
            commit('SET_UNAUTHORIZED_ERROR', errors)
          } else if (error.response.status === 404) {
            commit('SET_NOT_FOUND_ERROR', errors)
          } else {
            commit('SET_ERROR', errors)
          }
          reject(error)
        })
        .finally(() => {
          commit('SET_SUCCESS')
        })
    })
  },
  getOrganizations({commit}) {
    return new Promise((resolve, reject) => {
      commit('SET_REQUEST')
      OrganizationApi.getOrganizations()
        .then(response => {
          let o = response.data
          commit('SET_ORGANIZATIONS', o)
          resolve(o)
        })
        .catch(error => {
          let errors = ''
          if (error.response.data && error.response.data.errors) {
            errors = error.response.data.errors
          }
          if (error.response.status === 401) {
            commit('SET_UNAUTHORIZED_ERROR', errors)
          } else {
            commit('SET_ERROR', errors)
          }
          reject(error)
        })
        .finally(() => {
          commit('SET_SUCCESS')
        })
    })
  },
  updateOrganization({commit}, params) {
    commit('SET_ORGANIZATION', params)
  }
}

//export store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
