import Vuex from 'vuex'
import Vue from 'vue'
import DoctorApi from '@/plugins/axios/api/doctor'

//load Vuex
Vue.use(Vuex)

const initialState = () => ({
  status: '',
  errors: '',
  doctors: [],
  doctor: {}
})

//to handle state
const state = initialState()

//to handle state
const getters = {
  doctors: state => state.doctors,
  doctor: state => state.doctor
}

//to handle mutations
const mutations = {
  SET_REQUEST(state) {
    state.status = 'loading'
  },
  SET_SUCCESS(state) {
    state.status = 'success'
  },
  SET_BAD_REQUEST_ERROR(state, errors) {
    state.status = 'bad_request'
    state.errors = errors
  },
  SET_UNAUTHORIZED_ERROR(state, errors) {
    state.status = 'unauthorized_error'
    state.errors = errors
  },
  SET_NOT_FOUND_ERROR(state, errors) {
    state.status = 'not_found_error'
    state.errors = errors
  },
  SET_ERROR(state, errors) {
    state.status = 'error'
    state.errors = errors
  },
  SET_DOCTORS(state, doctors) {
    state.doctors = doctors
  },
  SET_DOCTOR(state, doctor) {
    state.doctor = doctor
  },
  SET_RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}

//to handle actions
const actions = {
  getDoctors({commit}, organizationId) {
    return new Promise((resolve, reject) => {
      commit('SET_REQUEST')
      DoctorApi.getDoctors(organizationId)
        .then(response => {
          let d = response.data
          commit('SET_DOCTORS', d)
          resolve(d)
        })
        .catch(error => {
          let errors = ''
          if (error.response.data && error.response.data.errors) {
            errors = error.response.data.errors
          }
          if (error.response.status === 400) {
            commit('SET_BAD_REQUEST_ERROR', errors)
          } else if (error.response.status === 401) {
            commit('SET_UNAUTHORIZED_ERROR', errors)
          } else if (error.response.status === 404) {
            commit('SET_NOT_FOUND_ERROR', errors)
          } else {
            commit('SET_ERROR', errors)
          }
          reject(error)
        })
        .finally(() => {
          commit('SET_SUCCESS')
        })
    })
  },
  getDoctorDetail({commit}, params) {
    return new Promise((resolve, reject) => {
      commit('SET_REQUEST')
      DoctorApi.getDoctorDetail(params)
        .then(response => {
          let d = response.data
          commit('SET_DOCTOR', d)
          resolve(d)
        })
        .catch(error => {
          let errors = ''
          if (error.response.data && error.response.data.errors) {
            errors = error.response.data.errors
          }
          if (error.response.status === 400) {
            commit('SET_BAD_REQUEST_ERROR', errors)
          } else if (error.response.status === 401) {
            commit('SET_UNAUTHORIZED_ERROR', errors)
          } else if (error.response.status === 404) {
            commit('SET_NOT_FOUND_ERROR', errors)
          } else {
            commit('SET_ERROR', errors)
          }
          reject(error)
        })
        .finally(() => {
          commit('SET_SUCCESS')
        })
    })
  },
  updateDoctor({commit}, params) {
    commit('SET_DOCTOR', params)
  }
}

//export store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
