import axios from 'axios'

export default {
  postLogin(params) {
    return axios.post('/api/users/login', params)
  },
  getRegister(params) {
    return axios.get('/api/users/register', params)
  },
  putRegister(userId, token, params) {
    return axios.put(`/api/users/register/${userId}?token=${token}`, params)
  },
  postProvisionalRegister(params) {
    return axios.post('/api/users/pro_register', params)
  },
  postResetPassword(params) {
    return axios.post('/api/users/reset_password', params)
  },
  getSettingPassword(params) {
    return axios.get('/api/users/setting_password', params)
  },
  putSettingPassword(userDataId, token, origin, params) {
    return axios.put(`/api/users/setting_password/${userDataId}?token=${token}&origin=${origin}`, params)
  },
  getUser() {
    return axios.get('/api/users/my')
  },
  getUserAuthorities() {
    return axios.get('/api/users/authorities')
  },
  deleteLogout() {
    return axios.delete('/api/users/logout')
  }
}
