export const SUBJECTS_DATA = Object.freeze({
  0: '',
  1: '神経内科',
  2: '内科',
  3: '呼吸器内科',
  4: '循環器内科',
  5: '消化器内科（胃腸内科）',
  6: '腎臓内科',
  7: '糖尿病内科（代謝内科）',
  8: '血液内科',
  9: '皮膚科',
  10: 'アレルギー科',
  11: 'リウマチ科',
  12: '感染症内科',
  13: '小児科',
  14: '精神科',
  15: '心療内科',
  16: 'その他'
})

export const OCCUPATIONS_DATA = Object.freeze({
  0: '医師',
  1: '薬剤師',
  2: '看護師',
  3: 'その他の医療従事者'
})

export const PERMISSIONS_DATA = Object.freeze({
  0: 'オーナー',
  1: 'システム管理者',
  2: '医療従事者'
})

export const REGEX_KANA_DATA = Array(
  'あ-お',
  'か-ご',
  'さ-ぞ',
  'た-ど',
  'な-の',
  'は-ぽ',
  'ま-も',
  'や-よ',
  'ら-ろ',
  'わ-ん'
)

export const GENDER_DATA = Object.freeze({
  2: '不明',
  0: '男性',
  1: '女性',
  9: '適用不能'
})

export const WEARING_OFF_DATA = Object.freeze({
  1: 'あり',
  2: 'なし'
})

export const MONITORING_LABEL = Object.freeze({
  likely: 'あり',
  none: 'なし',
  unknown: '不明',
  value_relation: '関連',
  value_not_relation: '関連なし',
  unlikely: 'なし',
  normal: 'ふつう',
  terrible: 'ひどい'
})

export const PREFECTURE_DATA = Object.freeze({
  1: '愛知県',
  2: '愛媛県',
  3: '茨城県',
  4: '岡山県',
  5: '沖縄県',
  6: '岩手県',
  7: '岐阜県',
  8: '宮崎県',
  9: '宮城県',
  10: '京都府',
  11: '熊本県',
  12: '群馬県',
  13: '広島県',
  14: '香川県',
  15: '高知県',
  16: '佐賀県',
  17: '埼玉県',
  18: '三重県',
  19: '山形県',
  20: '山口県',
  21: '山梨県',
  22: '滋賀県',
  23: '鹿児島県',
  24: '秋田県',
  25: '新潟県',
  26: '神奈川県',
  27: '青森県',
  28: '静岡県',
  29: '石川県',
  30: '千葉県',
  31: '大阪府',
  32: '大分県',
  33: '長崎県',
  34: '長野県',
  35: '鳥取県',
  36: '島根県',
  37: '東京都',
  38: '徳島県',
  39: '栃木県',
  40: '奈良県',
  41: '富山県',
  42: '福井県',
  43: '福岡県',
  44: '福島県',
  45: '兵庫県',
  46: '北海道',
  47: '和歌山県'
})

export const USER_ROLES_DATA = Object.freeze({
  assign_system_admin: {
    general: false,
    system: true,
    owner: true
  },
  update_organization: {
    general: false,
    system: true,
    owner: true
  },
  create_doctor: {
    general: false,
    system: true,
    owner: true
  },
  update_doctor: {
    general: true,
    system: true,
    owner: true
  },
  create_patient: {
    general: true,
    system: true,
    owner: true
  },
  update_patient: {
    general: true,
    system: true,
    owner: true
  }
})

export const APPROVAL_STATUS_ARRAY = Array(
  '―',
  '確認する',
  '承認不可'
)

export const QR_CODES = Array(
  '―',
  'QRコード表示'
)

export const MEDICINE_NOTIFICATION_TIME = Object.freeze({
  1: '1回',
  2: '2回',
  3: '3回',
  4: '4回',
  5: '5回',
  6: '6回',
  7: '7回',
  8: '8回',
  9: '9回',
  10: '10回'
})

export const TONPUKU_DATA = Object.freeze({
  0: 'なし（ボタン非表示)',
  1: 'あり（ボタン表示)'
})

export default [
  SUBJECTS_DATA,
  OCCUPATIONS_DATA,
  PERMISSIONS_DATA,
  REGEX_KANA_DATA,
  MONITORING_LABEL,
  GENDER_DATA,
  WEARING_OFF_DATA,
  PREFECTURE_DATA,
  USER_ROLES_DATA,
  APPROVAL_STATUS_ARRAY,
  QR_CODES,
  MEDICINE_NOTIFICATION_TIME,
  TONPUKU_DATA
]