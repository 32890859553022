import Vue from 'vue'
import vuetify from './plugins/vuetify'
import App from '../App.vue'
import router from './router'
import store from './store'
import Scrollspy from 'vue2-scrollspy'
import './mixins/common'
import './mixins/filters'
import './plugins/axios'
import Print from 'vue-print-nb'
// Global instruction 
Vue.use(Print)

// ブラウザバックを検知してコンポーネントで「this.$browserBackFlg」で使用できるようにする。
Vue.prototype.$browserBackFlg = false
history.replaceState(null, '', null)
window.addEventListener('popstate', function() {
  Vue.prototype.$browserBackFlg = true

  window.setTimeout(() => {
    Vue.prototype.$browserBackFlg = false
  }, 500)
})

document.addEventListener('DOMContentLoaded', () => {
  Vue.use(Scrollspy)

  const app = new Vue({
    router,
    vuetify,
    store,
    render: h => h(App)
  }).$mount()
  document.body.appendChild(app.$el)
})
