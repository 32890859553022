import Vuex from 'vuex'
import Vue from 'vue'
import UserApi from '@/plugins/axios/api/user'

//load Vuex
Vue.use(Vuex)

const initialState = () => ({
  status: '',
  errors: '',
  user: {},
  user_authorities: {}
})

//to handle state
const state = initialState()

//to handle state
const getters = {
  user_authorities: (state) => state.user_authorities,
  isAuthenticated: (state) => !!state.user && localStorage.getItem('access_token') != null,
  user: (state) => state.user
}

//to handle mutations
const mutations = {
  SET_REQUEST(state) {
    state.status = 'loading'
  },
  SET_SUCCESS(state) {
    state.status = 'success'
  },
  SET_BAD_REQUEST_ERROR(state, errors) {
    state.status = 'bad_request'
    state.errors = errors
  },
  SET_UNAUTHORIZED_ERROR(state, errors) {
    state.status = 'unauthorized_error'
    state.errors = errors
  },
  SET_NOT_FOUND_ERROR(state, errors) {
    state.status = 'not_found_error'
    state.errors = errors
  },
  SET_ERROR(state, errors) {
    state.status = 'error'
    state.errors = errors
  },
  SET_USER_AUTHORITIES(state, user_authorities) {
    state.user_authorities = user_authorities
  },
  SET_USER(state, user) {
    state.user = user
  },
  SET_RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key]
    })
  }
}

//to handle actions
const actions = {
  async getUserAuthorities({ commit }) {
    commit('SET_REQUEST')
    await UserApi.getUserAuthorities()
      .then((response) => {
        let s = response.data
        commit('SET_SUCCESS')
        localStorage.setItem('user_authorities', JSON.stringify(s))
        commit('SET_USER_AUTHORITIES', JSON.parse(localStorage.getItem('user_authorities')))
      })
      .catch((error) => {
        let errors = ''
        if (error.response.data && error.response.data.errors) {
          errors = error.response.data.errors
        }
        commit('SET_ERROR', errors)
        // eslint-disable-next-line
        reject(error)
      })
  },
  async getUser({ commit }) {
    commit('SET_REQUEST')
    await UserApi.getUser()
      .then((response) => {
        const u = response.data
        commit('SET_SUCCESS')
        commit('SET_USER', u)
      })
      .catch((error) => {
        let errors = ''
        if (error.response.data && error.response.data.errors) {
          errors = error.response.data.errors
        }
        commit('SET_ERROR', errors)
        // eslint-disable-next-line
        reject(error)
      })
  },
  updateUser({ commit }, params) {
    commit('SET_USER', params)
  },
  reset({ commit }) {
    localStorage.removeItem('access_token')
    localStorage.removeItem('access_token_expired_at')
    localStorage.removeItem('current_organization_id')
    localStorage.removeItem('current_user_authority')
    localStorage.removeItem('user_authorities')
    commit('SET_RESET')
  }
}

//export store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
