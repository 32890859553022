import axios from 'axios'

export default {
  getPrograms() {
    return axios.get('/api/programs')
  },
  getProgramsByOrganization(organizationId) {
    return axios.get('/api/programs?organization_id=' + organizationId)
  },
  getOccupations() {
    return axios.get('/api/occupations')
  },
  getAddressByZipcode(zipcode) {
    return axios.get('/api/zipcode/' + zipcode)
  }
}
