// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#5532EF',
        secondary: '#EC402D',
        accent: '#BF2520',
        error: '#DB3434',
        warning: '#E0B82A',
        info: '#4485B5',
        success: '#44B55D',
        background: '#FAF7F9'
      }
    }
  }
}

export default new Vuetify(opts)
