<template>
  <router-view />
</template>

<style scoped>
</style>

<script>
// routerで設定したタイトルとめたタグを反映する
export default {
  methods : {
    createTitle : function(routeInstance){
      // タイトルを設定
      if(routeInstance.meta.title){
        let setTitle = routeInstance.meta.title + ' | Healthcare Total Management Platform'
        document.title = setTitle
      } else {
        document.title = 'Healthcare Total Management Platform'
      }
    } 
  },
  mounted : function() {
    let routeInstance = this.$route
    this.createTitle(routeInstance)
  },
  watch: {
    '$route' (routeInstance) {
      this.createTitle(routeInstance)
    }
  }
}
</script>
