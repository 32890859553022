import Vue from 'vue'

Vue.mixin({
  methods: {
    // Auth user
    authUser: function() {
      return this.$store.getters['auth/user']
    },
    // is can
    can: function (subject, role) {
      const user_authorities = this.$store.getters['auth/user_authorities']
      return user_authorities[subject] != null && user_authorities[subject][role]
    },
    // updateable self only
    updateableSelfOnly: function (current_user_id, target_id) {
      if (this.can('doctor', 'updateable') == false) return false
      if (this.can('doctor', 'updateable_self_only') == false) return true
      return parseInt(current_user_id) == parseInt(target_id)
    },
    // updateable patient
    updateablePatient: function (current_user_id) {
      if (this.can('patient', 'updateable') == false) return false
      if (this.can('patient', 'updateable_self_patient_only') == false) return true
      return this.isSelfPatient(current_user_id)
    },
    isAdmin: function (user_authority) {
      return user_authority == 99
    },
    // is self patient
    isSelfPatient: function(current_user_id) {
      const patient = this.$store.getters['patient/patient']
      return patient.users.findIndex((user) => parseInt(user.id) == parseInt(current_user_id)) > -1
    },
    // full name
    fullName: function (last_name, first_name) {
      let d_last_name = (last_name == null) ? '' : last_name
      let d_first_name = (first_name == null) ? '' : first_name
      return d_last_name.concat(d_first_name)
    },
    // today date
    todayDate: function() {
      let today = new Date().toISOString().split('T')[0]
      return today
    },
    // clearAllStore
    clearAllStore: async function() {
      this.$store.dispatch('clearAll')
    }
  }
})
