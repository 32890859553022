import axios from 'axios'

export default {
  putOrganization(id, params) {
    return axios.put('/api/organizations/' + id, params)
  },
  postOrganization() {
    return axios.post('/api/organizations/create')
  },
  deleteOrganization(id) {
    return axios.put('/api/organizations/delete/' + id)
  },
  getOrganization(id) {
    return axios.get(`/api/organizations/${id}`)
  },
  getOrganizations() {
    return axios.get('/api/organizations')
  },
  /* eslint-disable */
  postOrganization(params) {
    return axios.post('/api/organizations', params)
  },
  /* eslint-enable*/
}
