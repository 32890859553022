import Vuex from 'vuex'
import Vue from 'vue'
import CommonApi from '@/plugins/axios/api/common'

//load Vuex
Vue.use(Vuex)

const initialState = () => ({
  status: '',
  errors: '',
  programs: [],
  programsByOrganization: [],
  occupations: []
})

//to handle state
const state = initialState()

//to handle state
const getters = {
  programs: state => state.programs,
  programsByOrganization: state => state.programsByOrganization,
  occupations: state => state.occupations
}

//to handle mutations
const mutations = {
  SET_REQUEST(state) {
    state.status = 'loading'
  },
  SET_SUCCESS(state) {
    state.status = 'success'
  },
  SET_BAD_REQUEST_ERROR(state, errors) {
    state.status = 'bad_request'
    state.errors = errors
  },
  SET_UNAUTHORIZED_ERROR(state, errors) {
    state.status = 'unauthorized_error'
    state.errors = errors
  },
  SET_NOT_FOUND_ERROR(state, errors) {
    state.status = 'not_found_error'
    state.errors = errors
  },
  SET_ERROR(state, errors) {
    state.status = 'error'
    state.errors = errors
  },
  SET_PROGRAMS(state, programs) {
    state.programs = programs
  },
  SET_PROGRAMS_BY_ORGANIZATION(state, programs) {
    state.programsByOrganization = programs
  },
  SET_OCCUPATIONS(state, occupations) {
    state.occupations = occupations
  },
  SET_RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  }
}

//to handle actions
const actions = {
  getPrograms({commit}) {
    return new Promise((resolve, reject) => {
      commit('SET_REQUEST')
      CommonApi.getPrograms()
        .then(response => {
          let p = response.data
          commit('SET_PROGRAMS', p)
          resolve(p)
        })
        .catch(error => {
          let errors = ''
          if (error.response.data && error.response.data.errors) {
            errors = error.response.data.errors
          }
          commit('SET_ERROR', errors)
          reject(error)
        })
        .finally(() => {
          commit('SET_SUCCESS')
        })
    })
  },
  getProgramsByOrganization({commit}, organizationId) {
    return new Promise((resolve, reject) => {
      commit('SET_REQUEST')
      CommonApi.getProgramsByOrganization(organizationId)
        .then(response => {
          let p = response.data
          commit('SET_PROGRAMS_BY_ORGANIZATION', p)
          resolve(p)
        })
        .catch(error => {
          let errors = ''
          if (error.response.data && error.response.data.errors) {
            errors = error.response.data.errors
          }
          if (error.response.status === 401) {
            commit('SET_UNAUTHORIZED_ERROR', errors)
          } else {
            commit('SET_ERROR', errors)
          }
          reject(error)
        })
        .finally(() => {
          commit('SET_SUCCESS')
        })
    })
  },
  getOccupations({commit}) {
    return new Promise((resolve, reject) => {
      commit('SET_REQUEST')
      CommonApi.getOccupations()
        .then(response => {
          let o = response.data
          commit('SET_OCCUPATIONS', o)
          resolve(o)
        })
        .catch(error => {
          let errors = ''
          if (error.response.data && error.response.data.errors) {
            errors = error.response.data.errors
          }
          commit('SET_ERROR', errors)
          reject(error)
        })
        .finally(() => {
          commit('SET_SUCCESS')
        })
    })
  }
}

//export store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
