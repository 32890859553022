import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from 'vuex-persistedstate'
import auth from './modules/auth'
import patient from './modules/patient'
import doctor from './modules/doctor'
import common from './modules/common'
import organization from './modules/organization'

//load Vuex
Vue.use(Vuex)

//export store module
export default new Vuex.Store({
  modules: {
    auth: auth,
    patient: patient,
    doctor: doctor,
    common: common,
    organization: organization
  },
  actions: {
    clearAll({ commit, dispatch }){
      dispatch('auth/reset')
      commit('patient/SET_RESET')
      commit('doctor/SET_RESET')
      commit('common/SET_RESET')
      commit('organization/SET_RESET')
    }
  },
  plugins: [createPersistedState()]
})
