import axios from 'axios'

export default {
  getDoctors(organizationId) {
    return axios.get(`/api/doctors?organization_id=${organizationId}`)
  },
  getDoctorDetail(params) {
    return axios.get(`/api/doctors/${params.doctorId}?organization_id=${params.organizationId}`)
  },
  postDoctor(params) {
    return axios.post('/api/doctors', params)
  },
  putDoctor(doctorId, params) {
    return axios.put(`/api/doctors/${doctorId}`, params)
  },
  isAssignedPatient(doctorId, patientId) {
    return axios.get(`/api/doctors/${doctorId}/assigned_patient?patient_id=${patientId}`)
  }
}
