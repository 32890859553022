import Vuex from 'vuex'
import Vue from 'vue'
import PatientApi from '@/plugins/axios/api/patient'

//load Vuex
Vue.use(Vuex)

const initialState = () => ({
  status: '',
  inquiry_status: '',
  pd_diary_status: '',
  activity_status: '',
  step_status: '',
  falls_status: '',
  tremor_status: '',
  dyskinesia_status: '',
  wearing_status: '',
  applewatch_status: '',
  medication_setting_status: '',
  examination_date_status: '',
  current_month_average_status:'',
  previous_month_average_status:'',
  inquiry_differences_status:'',
  diurnal_fluctuation_status: '',
  bf_exam_text_inquiry_status: '',
  aw_wearing_time_status: '',
  errors: '',
  patients: [],
  patient: {},
  inquiry_results: {},
  monitoring_results: [],
  monitoring_data: [],
  falls: [],
  activity_amount: [],
  number_of_step: [],
  wearing_off: [],
  tremor: [],
  dyskinesia: [],
  apple_watch_wearing_time: [],
  pd_diary: [],
  examination_date_management: {},
  medication_setting: {},
  examination_dates: {},
  current_month_average: {},
  previous_month_average: {},
  inquiry_differences: [],
  d_fluctuation_number_of_step: [],
  d_fluctuation_tremor: [],
  d_fluctuation_dyskinesia: [],
  d_fluctuation_wearing_off: [],
  d_fluctuation_wearing_off_by_date: [],
  d_fluctuation_compliance_rate: [],
  d_fluctuation_tonpuku_records: [],
  d_fluctuation_data_flag: {},
  aw_wearing_time: 0
})

//to handle state
const state = initialState()

//to handle state
const getters = {
  patients: state => {
    return state.patients.filter(patient => parseInt(patient.approval_status) == 1)
  },
  patient: state => state.patient,
  inquiry_results: state => state.inquiry_results,
  monitoring_results: state => state.monitoring_results,
  falls: state => state.falls,
  activity_amount: state => state.activity_amount,
  number_of_step: state => state.number_of_step,
  wearing_off: state => state.wearing_off,
  tremor: state => state.tremor,
  dyskinesia: state => state.dyskinesia,
  apple_watch_wearing_time: state => state.apple_watch_wearing_time,
  pd_diary: state => state.pd_diary,
  medication_setting: state => state.medication_setting,
  examination_dates: state => state.examination_dates,
  current_month_average: state => state.current_month_average,
  previous_month_average: state => state.previous_month_average,
  requestPatients: state => {
    return state.patients.filter(patient => parseInt(patient.approval_status) != 1)
  },
  patientsByProgram: (state) => (program_id) => {
    return state.patients.filter(p => parseInt(p.approval_status) == 1 && p.programs.findIndex(program => program.program_id == program_id) != -1)
  },
  inquiryDifferences: state => {
    return state.inquiry_differences.filter(v => v.deteriorated != 'no_change' || v.severe_answer)
  },
  examination_date_management: state => state.examination_date_management,
  d_fluctuation_number_of_step: state => state.d_fluctuation_number_of_step,
  d_fluctuation_tremor: state =>state.d_fluctuation_tremor,
  d_fluctuation_dyskinesia: state =>state.d_fluctuation_dyskinesia,
  d_fluctuation_wearing_off: state =>state.d_fluctuation_wearing_off,
  d_fluctuation_wearing_off_by_date: state =>state.d_fluctuation_wearing_off_by_date,
  d_fluctuation_compliance_rate: state =>state.d_fluctuation_compliance_rate,
  d_fluctuation_tonpuku_records: state =>state.d_fluctuation_tonpuku_records,
  aw_wearing_time: state =>state.aw_wearing_time
}

//to handle mutations
const mutations = {
  SET_REQUEST(state) {
    state.status = 'loading'
  },
  SET_SUCCESS(state) {
    state.status = 'success'
  },
  SET_BAD_REQUEST_ERROR(state, errors) {
    state.status = 'bad_request'
    state.errors = errors
  },
  SET_UNAUTHORIZED_ERROR(state, errors) {
    state.status = 'unauthorized_error'
    state.errors = errors
  },
  SET_NOT_FOUND_ERROR(state, errors) {
    state.status = 'not_found_error'
    state.errors = errors
  },
  SET_ERROR(state, errors) {
    state.status = 'error'
    state.errors = errors
  },
  SET_INQUIRY_REQUEST(state) {
    state.inquiry_status = 'loading'
  },
  SET_INQUIRY_SUCCESS(state) {
    state.inquiry_status = 'success'
  },
  SET_PD_DIARY_REQUEST(state) {
    state.pd_diary_status = 'loading'
  },
  SET_PD_DIARY_SUCCESS(state) {
    state.pd_diary_status = 'success'
  },
  SET_ACTIVITY_REQUEST(state) {
    state.activity_status = 'loading'
  },
  SET_STEP_REQUEST(state) {
    state.step_status = 'loading'
  },
  SET_FALLS_REQUEST(state) {
    state.falls_status = 'loading'
  },
  SET_TREMOR_REQUEST(state) {
    state.tremor_status = 'loading'
  },
  SET_DYSKINESIA_REQUEST(state) {
    state.dyskinesia_status = 'loading'
  },
  SET_WEARING_REQUEST(state) {
    state.wearing_status = 'loading'
  },
  SET_APPLEWATCH_REQUEST(state) {
    state.applewatch_status = 'loading'
  },
  SET_AW_WEARING_TIME_REQUEST(state) {
    state.aw_wearing_time_status = 'loading'
  },
  SET_ACTIVITY_SUCCESS(state) {
    state.activity_status = 'success'
  },
  SET_STEP_SUCCESS(state) {
    state.step_status = 'success'
  },
  SET_FALLS_SUCCESS(state) {
    state.falls_status = 'success'
  },
  SET_TREMOR_SUCCESS(state) {
    state.tremor_status = 'success'
  },
  SET_DYSKINESIA_SUCCESS(state) {
    state.dyskinesia_status = 'success'
  },
  SET_WEARING_SUCCESS(state) {
    state.wearing_status = 'success'
  },
  SET_APPLEWATCH_SUCCESS(state) {
    state.applewatch_status = 'success'
  },
  SET_AW_WEARING_TIME_SUCCESS(state) {
    state.aw_wearing_time_status = 'success'
  },
  SET_MEDICATION_SETTING_REQUEST(state) {
    state.medication_setting_status = 'loading'
  },
  SET_MEDICATION_SETTING_SUCCESS(state) {
    state.medication_setting_status = 'success'
  },
  SET_EXAMINATION_DATE_REQUEST(state) {
    state.examination_date_status = 'loading'
  },
  SET_EXAMINATION_DATE_SUCCESS(state) {
    state.examination_date_status = 'success'
  },
  SET_CURRENT_MONTH_AVERAGE_REQUEST(state) {
    state.current_month_average_status = 'loading'
  },
  SET_CURRENT_MONTH_AVERAGE_SUCCESS(state) {
    state.current_month_average_status = 'success'
  },
  SET_PREVIOUS_MONTH_AVERAGE_REQUEST(state) {
    state.previous_month_average_status = 'loading'
  },
  SET_PREVIOUS_MONTH_AVERAGE_SUCCESS(state) {
    state.previous_month_average_status = 'success'
  },
  SET_INQUIRY_DIFFERENCES_REQUEST(state) {
    state.inquiry_differences_status = 'loading'
  },
  SET_INQUIRY_DIFFERENCES_SUCCESS(state) {
    state.inquiry_differences_status = 'success'
  },
  SET_DIURNAL_FLUCTUATION_REQUEST(state) {
    state.diurnal_fluctuation_status = 'loading'
  },
  SET_DIURNAL_FLUCTUATION_SUCCESS(state) {
    state.diurnal_fluctuation_status = 'success'
  },
  SET_BEFORE_EXAM_TEXT_INQUIRY_REQUEST(state) {
    state.bf_exam_text_inquiry_status = 'loading'
  },
  SET_BEFORE_EXAM_TEXT_INQUIRY_SUCCESS(state) {
    state.bf_exam_text_inquiry_status = 'success'
  },
  SET_PATIENTS(state, patients) {
    state.patients = patients
  },
  SET_PATIENT(state, patient) {
    state.patient = patient
  },
  SET_INQUIRY_RESULT(state, inquiry_results) {
    state.inquiry_results = inquiry_results
  },
  SET_ACTIVITY_AMOUNT(state, activity_amount) {
    state.activity_amount = activity_amount
  },
  SET_NUMBER_OF_STEP(state, number_of_step) {
    state.number_of_step = number_of_step
  },
  SET_NUMBER_OF_FALL(state, falls) {
    state.falls = falls
  },
  SET_TREMOR(state, tremor) {
    state.tremor = tremor
  },
  SET_DYSKINESIA(state, dyskinesia) {
    state.dyskinesia = dyskinesia
  },
  SET_WEARING_OFF(state, wearing_off) {
    state.wearing_off = wearing_off
  },
  SET_WEARING_ON_TIME(state, apple_watch_wearing_time) {
    state.apple_watch_wearing_time = apple_watch_wearing_time
  },
  SET_MONITORING_RESULT(state, monitoring_results) {
    state.monitoring_results = monitoring_results
  },
  SET_PD_DIARY(state, pd_diary) {
    state.pd_diary = pd_diary
  },
  SET_RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_BEFORE_EXAM_TEXT_INQUIRY(state, inquiry_text) {
    state.examination_date_management = inquiry_text
  },
  SET_MEDICATION_SETTING(state, medication_setting) {
    state.medication_setting = medication_setting
  },
  SET_EXAMINATION_DATES(state, examination_dates) {
    state.examination_dates = examination_dates
  },
  SET_CUR_MONTH_AVERAGE(state, current_month_average) {
    state.current_month_average = current_month_average
  },
  SET_PREV_MONTH_AVERAGE(state, previous_month_average) {
    state.previous_month_average = previous_month_average
  },
  SET_AW_WEARING_TIME(state, aw_wearing_time) {
    state.aw_wearing_time = aw_wearing_time
  },
  SET_INQUIRY_DIFFERENCES(state, inquiry_differences) {
    state.inquiry_differences = inquiry_differences
  },
  SET_D_FLUCTUATION_NUMBER_OF_STEP(state, number_of_step) {
    state.d_fluctuation_number_of_step = number_of_step
  },
  SET_D_FLUCTUATION_TREMOR(state, tremor) {
    state.d_fluctuation_tremor = tremor
  },
  SET_D_FLUCTUATION_DYSKINESIA(state, dyskinesia) {
    state.d_fluctuation_dyskinesia = dyskinesia
  },
  SET_D_FLUCTUATION_WEARING_OFF(state, wearing_off) {
    state.d_fluctuation_wearing_off = wearing_off
  },
  SET_D_FLUCTUATION_WEARING_OFF_BY_DATE(state, wearing_off_by_date) {
    state.d_fluctuation_wearing_off_by_date = wearing_off_by_date
  },
  SET_D_FLUCTUATION_COMPLIANCE_RATE(state, medication_compliance_rate) {
    state.d_fluctuation_compliance_rate = medication_compliance_rate
  },
  SET_D_FLUCTUATION_TONPUKU_RECORDS(state, tonpuku_medication_records) {
    state.d_fluctuation_tonpuku_records = tonpuku_medication_records
  },
  SET_D_FLUCTUATION_DATA_FLAG(state, data_flag) {
    state.d_fluctuation_data_flag = data_flag
  }
}

//to handle actions
const actions = {
  getPatients({commit}, organizationId) {
    return new Promise((resolve, reject) => {
      commit('SET_REQUEST')
      PatientApi.getPatients(organizationId)
        .then(response => {
          let p = response.data
          commit('SET_PATIENTS', p)
          resolve(p)
        })
        .catch(error => {
          let errors = ''
          if (error.response.data && error.response.data.errors) {
            errors = error.response.data.errors
          }
          if (error.response.status === 400) {
            commit('SET_BAD_REQUEST_ERROR', errors)
          } else if (error.response.status === 401) {
            commit('SET_UNAUTHORIZED_ERROR', errors)
          } else if (error.response.status === 404) {
            commit('SET_NOT_FOUND_ERROR', errors)
          } else {
            commit('SET_ERROR', errors)
          }
          reject(error)
        })
        .finally(() => {
          commit('SET_SUCCESS')
        })
    })
  },
  getPatientDetail({commit}, params) {
    return new Promise((resolve, reject) => {
      commit('SET_REQUEST')
      PatientApi.getPatientDetail(params)
        .then(response => {
          let p = response.data
          commit('SET_PATIENT', p)
          resolve(p)
        })
        .catch(error => {
          let errors = ''
          if (error.response.data && error.response.data.errors) {
            errors = error.response.data.errors
          }
          if (error.response.status === 400) {
            commit('SET_BAD_REQUEST_ERROR', errors)
          } else if (error.response.status === 401) {
            commit('SET_UNAUTHORIZED_ERROR', errors)
          } else if (error.response.status === 404) {
            commit('SET_NOT_FOUND_ERROR', errors)
          } else {
            commit('SET_ERROR', errors)
          }
          reject(error)
        })
        .finally(() => {
          commit('SET_SUCCESS')
        })
    })
  },
  getInquiryResults({commit}, patientId) {
    return new Promise((resolve, reject) => {
      commit('SET_REQUEST')
      commit('SET_INQUIRY_REQUEST')

      PatientApi.getInquiryResults(patientId)
        .then(response => {
          let p = response.data
          commit('SET_INQUIRY_RESULT', p)
          resolve(p)
        })
        .catch(error => {
          let errors = ''
          if (error.response.data && error.response.data.errors) {
            errors = error.response.data.errors
          }
          if (error.response.status === 400) {
            commit('SET_BAD_REQUEST_ERROR', errors)
          } else if (error.response.status === 401) {
            commit('SET_UNAUTHORIZED_ERROR', errors)
          } else if (error.response.status === 404) {
            commit('SET_NOT_FOUND_ERROR', errors)
          } else {
            commit('SET_ERROR', errors)
          }
          reject(error)
        })
        .finally(() => {
          commit('SET_SUCCESS')
          commit('SET_INQUIRY_SUCCESS')
        })
    })
  },
  getPrevNextInquiryResults({commit}, params) {
    return new Promise((resolve, reject) => {
      commit('SET_REQUEST')
      commit('SET_INQUIRY_REQUEST')

      PatientApi.getPrevNextInquiryResults(params.patientId, params.inquiryId)
        .then(response => {
          let p = response.data
          commit('SET_INQUIRY_RESULT', p)
          resolve(p)
        })
        .catch(error => {
          let errors = ''
          if (error.response.data && error.response.data.errors) {
            errors = error.response.data.errors
          }
          if (error.response.status === 400) {
            commit('SET_BAD_REQUEST_ERROR', errors)
          } else if (error.response.status === 401) {
            commit('SET_UNAUTHORIZED_ERROR', errors)
          } else if (error.response.status === 404) {
            commit('SET_NOT_FOUND_ERROR', errors)
          } else {
            commit('SET_ERROR', errors)
          }
          reject(error)
        })
        .finally(() => {
          commit('SET_SUCCESS')
          commit('SET_INQUIRY_SUCCESS')
        })
    })
  },
  getMonitoringResults({commit}, monitoringInputData) {
    return new Promise((resolve, reject) => {
      commit('SET_REQUEST')
      if (monitoringInputData.types.includes('activity_amount')) {
        commit('SET_ACTIVITY_REQUEST')
      }
      if (monitoringInputData.types.includes('number_of_step')) {
        commit('SET_STEP_REQUEST')
      }
      if (monitoringInputData.types.includes('falls')) {
        commit('SET_FALLS_REQUEST')
      }
      if (monitoringInputData.types.includes('tremor')) {
        commit('SET_TREMOR_REQUEST')
      }
      if (monitoringInputData.types.includes('dyskinesia')) {
        commit('SET_DYSKINESIA_REQUEST')
      }
      if (monitoringInputData.types.includes('wearing_off')) {
        commit('SET_WEARING_REQUEST')
      }
      if (monitoringInputData.types.includes('apple_watch_wearing_time')) {
        commit('SET_APPLEWATCH_REQUEST')
      }
      PatientApi.getMonitoringResults(
        monitoringInputData.patientId,
        monitoringInputData.types,
        monitoringInputData.startDate,
        monitoringInputData.endDate,
        monitoringInputData.mode
      )
        .then(response => {
          let p = response.data
          if (monitoringInputData.types.includes('activity_amount')) {  
            commit('SET_ACTIVITY_AMOUNT', p.activity_amount)
          }
          if (monitoringInputData.types.includes('number_of_step')) {
            commit('SET_NUMBER_OF_STEP', p.number_of_step)
          }
          if (monitoringInputData.types.includes('falls')) {
            commit('SET_NUMBER_OF_FALL', p.falls)
          }
          if (monitoringInputData.types.includes('tremor')) {
            commit('SET_TREMOR', p.tremor)
          }
          if (monitoringInputData.types.includes('dyskinesia')) {
            commit('SET_DYSKINESIA', p.dyskinesia)
          }
          if (monitoringInputData.types.includes('wearing_off')) {
            commit('SET_WEARING_OFF', p.wearing_off)
          }
          if (monitoringInputData.types.includes('apple_watch_wearing_time')) {
            commit('SET_WEARING_ON_TIME', p.apple_watch_wearing_time)
          }
          resolve(p)
        })
        .catch(error => {
          let errors = ''
          if (error.response.data && error.response.data.errors) {
            errors = error.response.data.errors
          }
          if (error.response.status === 400) {
            commit('SET_BAD_REQUEST_ERROR', errors)
          } else if (error.response.status === 401) {
            commit('SET_UNAUTHORIZED_ERROR', errors)
          } else if (error.response.status === 404) {
            commit('SET_NOT_FOUND_ERROR', errors)
          } else {
            commit('SET_ERROR', errors)
          }
          reject(error)
        })
        .finally(() => {
          commit('SET_SUCCESS')
          if (monitoringInputData.types.includes('activity_amount')) {
            commit('SET_ACTIVITY_SUCCESS')
          }
          if (monitoringInputData.types.includes('number_of_step')) {
            commit('SET_STEP_SUCCESS')
          }
          if (monitoringInputData.types.includes('falls')) {
            commit('SET_FALLS_SUCCESS')
          }
          if (monitoringInputData.types.includes('tremor')) {
            commit('SET_TREMOR_SUCCESS')
          }
          if (monitoringInputData.types.includes('dyskinesia')) {
            commit('SET_DYSKINESIA_SUCCESS')
          }
          if (monitoringInputData.types.includes('wearing_off')) {
            commit('SET_WEARING_SUCCESS')
          }
          if (monitoringInputData.types.includes('apple_watch_wearing_time')) {
            commit('SET_APPLEWATCH_SUCCESS')
          }
        })
    })
  },
  getPdDiary({commit}, patientId) {
    return new Promise((resolve, reject) => {
      commit('SET_REQUEST')
      commit('SET_PD_DIARY_REQUEST')
      PatientApi.getPdDiary(patientId)
        .then(response => {
          let p = response.data
          commit('SET_PD_DIARY', p)
          resolve(p)
        })
        .catch(error => {
          let errors = ''
          if (error.response.data && error.response.data.errors) {
            errors = error.response.data.errors
          }
          if (error.response.status === 400) {
            commit('SET_BAD_REQUEST_ERROR', errors)
          } else if (error.response.status === 401) {
            commit('SET_UNAUTHORIZED_ERROR', errors)
          } else if (error.response.status === 404) {
            commit('SET_NOT_FOUND_ERROR', errors)
          } else {
            commit('SET_ERROR', errors)
          }
          reject(error)
        })
        .finally(() => {
          commit('SET_SUCCESS')
          commit('SET_PD_DIARY_SUCCESS')
        })
    })
  },
  updatePatient({commit}, params) {
    commit('SET_PATIENT', params)
  },
  getBfExamTextInquiry({commit}, patientId) {
    return new Promise((resolve, reject) => {
      commit('SET_REQUEST')
      commit('SET_BEFORE_EXAM_TEXT_INQUIRY_REQUEST')
      PatientApi.getBfExamTextInquiry(patientId)
        .then(response => {
          let p = response.data
          commit('SET_BEFORE_EXAM_TEXT_INQUIRY', p)
          resolve(p)
        })
        .catch(error => {
          let errors = ''
          if (error.response.data && error.response.data.errors) {
            errors = error.response.data.errors
          }
          if (error.response.status === 400) {
            commit('SET_BAD_REQUEST_ERROR', errors)
          } else if (error.response.status === 401) {
            commit('SET_UNAUTHORIZED_ERROR', errors)
          } else if (error.response.status === 404) {
            commit('SET_NOT_FOUND_ERROR', errors)
          } else {
            commit('SET_ERROR', errors)
          }
          reject(error)
        })
        .finally(() => {
          commit('SET_SUCCESS')
          commit('SET_BEFORE_EXAM_TEXT_INQUIRY_SUCCESS')
        })
    })
  },
  getBfExamDateInquiry({commit}, params) {
    return new Promise((resolve, reject) => {
      commit('SET_REQUEST')
      PatientApi.getBfExamDateInquiry(params.patientId, params.examId)
        .then(response => {
          let p = response.data
          commit('SET_BEFORE_EXAM_TEXT_INQUIRY', p)
          resolve(p)
        })
        .catch(error => {
          let errors = ''
          if (error.response.data && error.response.data.errors) {
            errors = error.response.data.errors
          }
          if (error.response.status === 400) {
            commit('SET_BAD_REQUEST_ERROR', errors)
          } else if (error.response.status === 401) {
            commit('SET_UNAUTHORIZED_ERROR', errors)
          } else if (error.response.status === 404) {
            commit('SET_NOT_FOUND_ERROR', errors)
          } else {
            commit('SET_ERROR', errors)
          }
          reject(error)
        })
        .finally(() => {
          commit('SET_SUCCESS')
        })
    })
  },
  getMedicationSetting({commit}, patientId) {
    return new Promise((resolve, reject) => {
      commit('SET_REQUEST')
      commit('SET_MEDICATION_SETTING_REQUEST')
      PatientApi.getMedicationSetting(patientId)
        .then(response => {
          let p = response.data
          commit('SET_MEDICATION_SETTING', p)
          resolve(p)
        })
        .catch(error => {
          let errors = ''
          if (error.response.data && error.response.data.errors) {
            errors = error.response.data.errors
          }
          if (error.response.status === 400) {
            commit('SET_BAD_REQUEST_ERROR', errors)
          } else if (error.response.status === 401) {
            commit('SET_UNAUTHORIZED_ERROR', errors)
          } else if (error.response.status === 404) {
            commit('SET_NOT_FOUND_ERROR', errors)
          } else {
            commit('SET_ERROR', errors)
          }
          reject(error)
        })
        .finally(() => {
          commit('SET_SUCCESS')
          commit('SET_MEDICATION_SETTING_SUCCESS')
        })
    })
  },
  saveMedicationSetting({commit}, params) {
    commit('SET_MEDICATION_SETTING', params)
  },
  getExaminationDates({commit}, patientId) {
    return new Promise((resolve, reject) => {
      commit('SET_REQUEST')
      commit('SET_EXAMINATION_DATE_REQUEST')
      PatientApi.getExaminationDates(patientId)
        .then(response => {
          let p = response.data
          commit('SET_EXAMINATION_DATES', p)
          resolve(p)
        })
        .catch(error => {
          let errors = ''
          if (error.response.data && error.response.data.errors) {
            errors = error.response.data.errors
          }
          if (error.response.status === 400) {
            commit('SET_BAD_REQUEST_ERROR', errors)
          } else if (error.response.status === 401) {
            commit('SET_UNAUTHORIZED_ERROR', errors)
          } else if (error.response.status === 404) {
            commit('SET_NOT_FOUND_ERROR', errors)
          } else {
            commit('SET_ERROR', errors)
          }
          reject(error)
        })
        .finally(() => {
          commit('SET_SUCCESS')
          commit('SET_EXAMINATION_DATE_SUCCESS')
        })
    })
  },
  getCurMonthAverage({commit}, monitoringInputData) {
    return new Promise((resolve, reject) => {
      commit('SET_REQUEST')
      commit('SET_CURRENT_MONTH_AVERAGE_REQUEST')
      PatientApi.getAverageDifference(
        monitoringInputData.patientId,
        monitoringInputData.types,
        monitoringInputData.startDate,
        monitoringInputData.endDate
      )
        .then(response => {
          let cur_month_average = response.data
          if (Object.keys(cur_month_average).length > 0) {
            let tremor = cur_month_average.tremor.likely
            cur_month_average.tremor = tremor

            let dyskinesia = cur_month_average.dyskinesia.likely
            cur_month_average.dyskinesia = dyskinesia

            let times = 0
            let value = 0
            for(let i = 0; i < cur_month_average.wearing_off.length; i++) {
              times += cur_month_average.wearing_off[i].times
              value += cur_month_average.wearing_off[i].total_time
            }
            cur_month_average.wearing_off_times = times
            cur_month_average.wearing_off_value = value
            delete cur_month_average.wearing_off
          }
          commit('SET_CUR_MONTH_AVERAGE', cur_month_average)
          resolve(cur_month_average)
        })
        .catch(error => {
          let errors = ''
          if (error.response.data && error.response.data.errors) {
            errors = error.response.data.errors
          }
          if (error.response.status === 400) {
            commit('SET_BAD_REQUEST_ERROR', errors)
          } else if (error.response.status === 401) {
            commit('SET_UNAUTHORIZED_ERROR', errors)
          } else if (error.response.status === 404) {
            commit('SET_NOT_FOUND_ERROR', errors)
          } else {
            commit('SET_ERROR', errors)
          }
          reject(error)
        })
        .finally(() => {
          commit('SET_SUCCESS')
          commit('SET_CURRENT_MONTH_AVERAGE_SUCCESS')
        })
    })
  },
  getPrevMonthAverage({commit}, monitoringInputData) {
    return new Promise((resolve, reject) => {
      commit('SET_REQUEST')
      commit('SET_PREVIOUS_MONTH_AVERAGE_REQUEST')
      PatientApi.getAverageDifference(
        monitoringInputData.patientId,
        monitoringInputData.types,
        monitoringInputData.startDate,
        monitoringInputData.endDate
      )
        .then(response => {
          let pre_month_average = response.data
          if (Object.keys(pre_month_average).length > 0) {
            let tremor = pre_month_average.tremor.likely
            pre_month_average.tremor = tremor

            let dyskinesia = pre_month_average.dyskinesia.likely
            pre_month_average.dyskinesia = dyskinesia

            let times = 0
            let value = 0
            for(let i = 0; i < pre_month_average.wearing_off.length; i++) {
              times += pre_month_average.wearing_off[i].times
              value += pre_month_average.wearing_off[i].total_time
            }
            pre_month_average.wearing_off_times = times
            pre_month_average.wearing_off_value = value
            delete pre_month_average.wearing_off
          }
          commit('SET_PREV_MONTH_AVERAGE', pre_month_average)
          resolve(pre_month_average)
        })
        .catch(error => {
          let errors = ''
          if (error.response.data && error.response.data.errors) {
            errors = error.response.data.errors
          }
          if (error.response.status === 400) {
            commit('SET_BAD_REQUEST_ERROR', errors)
          } else if (error.response.status === 401) {
            commit('SET_UNAUTHORIZED_ERROR', errors)
          } else if (error.response.status === 404) {
            commit('SET_NOT_FOUND_ERROR', errors)
          } else {
            commit('SET_ERROR', errors)
          }
          reject(error)
        })
        .finally(() => {
          commit('SET_SUCCESS')
          commit('SET_PREVIOUS_MONTH_AVERAGE_SUCCESS')
        })
    })
  },
  getInquiryDifferences({commit}, inputData) {
    return new Promise((resolve, reject) => {
      commit('SET_REQUEST')
      commit('SET_INQUIRY_DIFFERENCES_REQUEST')
      PatientApi.getInquiryDifferences(
        inputData.patientId
      )
        .then(response => {
          let p = response.data
          commit('SET_INQUIRY_DIFFERENCES', p)
          resolve(p)
        })
        .catch(error => {
          let errors = ''
          if (error.response.data && error.response.data.errors) {
            errors = error.response.data.errors
          }
          if (error.response.status === 400) {
            commit('SET_BAD_REQUEST_ERROR', errors)
          } else if (error.response.status === 401) {
            commit('SET_UNAUTHORIZED_ERROR', errors)
          } else if (error.response.status === 404) {
            commit('SET_NOT_FOUND_ERROR', errors)
          } else {
            commit('SET_ERROR', errors)
          }
          reject(error)
        })
        .finally(() => {
          commit('SET_SUCCESS')
          commit('SET_INQUIRY_DIFFERENCES_SUCCESS')
        })
    })
  },
  getDiurnalFluctuationChart({commit}, monitoringInputData) {
    return new Promise((resolve, reject) => {
      commit('SET_DIURNAL_FLUCTUATION_REQUEST')

      if (Object.keys(monitoringInputData).includes('date')) {

        PatientApi.getDiurnalFluctuationChartByDate(
          monitoringInputData.patientId,
          monitoringInputData.types,
          monitoringInputData.date
        )
          .then(response => {
            let p = response.data
            if (Object.keys(p).length != 0){
              let flag = {}
              flag.previous = p.previous_data_flag
              commit('SET_D_FLUCTUATION_DATA_FLAG', flag)
              if (monitoringInputData.types.includes('number_of_step')) {
                commit('SET_D_FLUCTUATION_NUMBER_OF_STEP', p.number_of_step)
              }
              if (monitoringInputData.types.includes('tremor')) {
                commit('SET_D_FLUCTUATION_TREMOR', p.tremor)
              }
              if (monitoringInputData.types.includes('dyskinesia')) {
                commit('SET_D_FLUCTUATION_DYSKINESIA', p.dyskinesia)
              }
              if (monitoringInputData.types.includes('wearing_off')) {
                commit('SET_D_FLUCTUATION_WEARING_OFF_BY_DATE', p.wearing_off_by_date)
              }
              if (monitoringInputData.types.includes('medication_compliance_rate')) {
                commit('SET_D_FLUCTUATION_COMPLIANCE_RATE', p.medication_compliance_rate)
              }
              if (monitoringInputData.types.includes('tonpuku_medication_records')) {
                commit('SET_D_FLUCTUATION_TONPUKU_RECORDS', p.tonpuku_medication_records)
              }
            }
            resolve(p)
          })
          .catch(error => {
            let errors = ''
            if (error.response.data && error.response.data.errors) {
              errors = error.response.data.errors
            }
            if (error.response.status === 400) {
              commit('SET_BAD_REQUEST_ERROR', errors)
            } else if (error.response.status === 401) {
              commit('SET_UNAUTHORIZED_ERROR', errors)
            } else if (error.response.status === 404) {
              commit('SET_NOT_FOUND_ERROR', errors)
            } else {
              commit('SET_ERROR', errors)
            }
            reject(error)
          })
          .finally(() => {
            commit('SET_DIURNAL_FLUCTUATION_SUCCESS')
          })
      }
      else {
        PatientApi.getDiurnalFluctuationChart(
          monitoringInputData.patientId,
          monitoringInputData.types,
          monitoringInputData.startDate,
          monitoringInputData.endDate
        )
          .then(response => {
            let p = response.data
            if (Object.keys(p).length != 0){
              let flag = {}
              flag.previous = p.previous_data_flag
              commit('SET_D_FLUCTUATION_DATA_FLAG', flag)
              if (monitoringInputData.types.includes('number_of_step')) {
                commit('SET_D_FLUCTUATION_NUMBER_OF_STEP', p.number_of_step)
              }
              if (monitoringInputData.types.includes('tremor')) {
                commit('SET_D_FLUCTUATION_TREMOR', p.tremor)
              }
              if (monitoringInputData.types.includes('dyskinesia')) {
                commit('SET_D_FLUCTUATION_DYSKINESIA', p.dyskinesia)
              }
              if (monitoringInputData.types.includes('wearing_off')) {
                commit('SET_D_FLUCTUATION_WEARING_OFF', p.wearing_off)
              }
              if (monitoringInputData.types.includes('medication_compliance_rate')) {
                commit('SET_D_FLUCTUATION_COMPLIANCE_RATE', p.medication_compliance_rate)
              }
              if (monitoringInputData.types.includes('tonpuku_medication_records')) {
                commit('SET_D_FLUCTUATION_TONPUKU_RECORDS', p.tonpuku_medication_records)
              }
            }
            resolve(p)
          })
          .catch(error => {
            let errors = ''
            if (error.response.data && error.response.data.errors) {
              errors = error.response.data.errors
            }
            if (error.response.status === 400) {
              commit('SET_BAD_REQUEST_ERROR', errors)
            } else if (error.response.status === 401) {
              commit('SET_UNAUTHORIZED_ERROR', errors)
            } else if (error.response.status === 404) {
              commit('SET_NOT_FOUND_ERROR', errors)
            } else {
              commit('SET_ERROR', errors)
            }
            reject(error)
          })
          .finally(() => {
            commit('SET_DIURNAL_FLUCTUATION_SUCCESS')
          })
      }
    })
  },
  getAWWearingTime({commit}, monitoringInputData) {
    return new Promise((resolve, reject) => {
      commit('SET_AW_WEARING_TIME_REQUEST')
      PatientApi.getAverageDifference(
        monitoringInputData.patientId,
        monitoringInputData.types,
        monitoringInputData.startDate,
        monitoringInputData.endDate
      )
        .then(response => {
          let p = response.data
          let aw_wearing_time = 0
          if (Object.keys(p).includes('apple_watch_wearing_time')) {
            aw_wearing_time = p.apple_watch_wearing_time
          }
          commit('SET_AW_WEARING_TIME', aw_wearing_time)
          resolve(aw_wearing_time)
        })
        .catch(error => {
          let errors = ''
          if (error.response.data && error.response.data.errors) {
            errors = error.response.data.errors
          }
          if (error.response.status === 400) {
            commit('SET_BAD_REQUEST_ERROR', errors)
          } else if (error.response.status === 401) {
            commit('SET_UNAUTHORIZED_ERROR', errors)
          } else if (error.response.status === 404) {
            commit('SET_NOT_FOUND_ERROR', errors)
          } else {
            commit('SET_ERROR', errors)
          }
          reject(error)
        })
        .finally(() => {
          commit('SET_AW_WEARING_TIME_SUCCESS')
        })
    })
  }
}

//export store module
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
