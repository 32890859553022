import axios from 'axios'

export default {
  getPatients(organizationId) {
    return axios.get(`/api/patients?organization_id=${organizationId}`)
  },
  getPatientDetail(params) {
    return axios.get(`/api/patients/${params.patientId}?organization_id=${params.organizationId}`)
  },
  putPatientDetail(id, params) {
    return axios.put('/api/patients/' + id, params)
  },
  putMedicalInfo(id, params) {
    return axios.put('/api/patients/update_medical_info/' + id, params)
  },
  approveStatus(id) {
    return axios.put('/api/patients/approve_status/' + id)
  },
  postPatients(params) {
    return axios.post('/api/patients', params)
  },
  getInquiryResults(patientId) {
    return axios.get(`/api/patients/parkinson/${patientId}/inquiry_results`)
  },
  getPrevNextInquiryResults(patientId, inquiryId) {
    return axios.get(`/api/patients/parkinson/${patientId}/inquiry_results?inquiry_id=${inquiryId}`)
  },
  getPatientQrcode(patientId) {
    return axios.get(`/api/patients/${patientId}/qr_code`)
  },
  getPdDairyResults(patientId, questionnarie_id, from, to) {
    return axios.get(`/api/patients/parkinson/${patientId}/pd_diary_results?questionnaire_id=${questionnarie_id}&from=${from}&to=${to}`)
  },
  getMonitoringResults(patientId, types, from, to, mode) {
    let param_types = ''
    types.forEach(type => {
      param_types += `types[]=${type}&`
    })
    return axios.get(`/api/patients/parkinson/${patientId}/monitoring_results?${param_types}from=${from}&to=${to}&mode=${mode}`)
  },
  getPdDiary(patientId) {
    return axios.get(`/api/patients/parkinson/${patientId}/pd_diary`)
  },
  getBfExamTextInquiry(patientId) {
    return axios.get(`/api/patients/${patientId}/bf_exam_text_inquiry`)
  },
  getBfExamDateInquiry(patientId, examId) {
    return axios.get(`/api/patients/${patientId}/bf_exam_text_inquiry?exam_id=${examId}`)
  },
  getMedicationSetting(patientId) {
    return axios.get(`/api/patients/${patientId}/medication_setting`)
  },
  saveMedicationSetting(id, params) {
    return axios.put('/api/patients/'+ id+ '/medication_setting', params)
  },
  getExaminationDates(patientId) {
    return axios.get(`/api/patients/${patientId}/examination_dates`)
  },
  saveNextExamDate(id, params) {
    return axios.put('/api/patients/'+ id+ '/next_exam_date', params)
  },
  getAverageDifference(patientId, types, from, to) {
    let param_types = ''
    types.forEach(type => {
      param_types += `types[]=${type}&`
    })
    return axios.get(`/api/patients/parkinson/${patientId}/monitoring_results/average_differences?${param_types}from=${from}&to=${to}`)
  },
  getInquiryDifferences(patientId) {
    return axios.get(`/api/patients/parkinson/${patientId}/inquiry_results/differences`)
  },
  getDiurnalFluctuationChart(patientId, types, from, to) {
    let param_types = ''
    types.forEach(type => {
      param_types += `types[]=${type}&`
    })
    return axios.get(`/api/patients/parkinson/${patientId}/monitoring_results/diurnal_fluctuation?${param_types}from=${from}&to=${to}`)
  },
  getDiurnalFluctuationChartByDate(patientId, types, date) {
    let param_types = ''
    types.forEach(type => {
      param_types += `types[]=${type}&`
    })
    return axios.get(`/api/patients/parkinson/${patientId}/monitoring_results/diurnal_fluctuation?${param_types}date=${date}`)
  }
}
