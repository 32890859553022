import Vue from 'vue'
import { GENDER_DATA } from '@/static/const'
import { PERMISSIONS_DATA } from '@/static/const'
import moment from 'moment'

// capitalize
Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})
// age
Vue.filter('age', function (value) {
  if (!value) return ''
  value = value.toString()
  const today = new Date()
  const birthday = new Date(value)

  const today_mmdd = Number((today.getMonth() + 1) + ('0' + today.getDate()).slice(-2))
  const birthday_mmdd = Number((birthday.getMonth() + 1) + ('0' + birthday.getDate()).slice(-2))

  const age = today_mmdd >= birthday_mmdd ? today.getFullYear() - birthday.getFullYear() : today.getFullYear() - birthday.getFullYear() - 1
  return age
})
// gender
Vue.filter('gender', function (value) {
  // if (!value) return ''
  value = parseInt(value)
  return GENDER_DATA[value]
})
// date format
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(new Date(value)).format('YYYY/MM/DD')
  }
})
// datetime format
Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(new Date(value)).format('YYYY/MM/DD HH:mm')
  }
})
// ja datetime format
Vue.filter('formatJaDate', function(value) {
  if (value) {
    return moment(String(value)).format('YYYY年M月D日 (dd)')
  }
})
// time format
Vue.filter('formatTime', function(value) {
  if (value) {
    return moment(String(value)).format('HH:mm')
  }
})
// decimal format with no decimal place
Vue.filter('formatDecimal', function(value) {
  if (value != 0) {
    return Number(value).toLocaleString().toString()
  }
  else{
    return value
  }
})
// decimal format with 1 decimal place
Vue.filter('formatOneDecimal', function(value) {
  return Number(value).toLocaleString(undefined, {minimumFractionDigits: 1}).toString()
})
// user_authority
Vue.filter('permission', function (value) {
  // if (!value) return ''
  value = parseInt(value)
  return PERMISSIONS_DATA[value]
})

